<template>
    <v-content>
        <!--<v-breadcrumbs :items="items" large></v-breadcrumbs>-->
        <v-row>
            <v-col cols="12">
                <v-row justify="start">
                    <v-col
                            cols="2"
                            v-for="title in titles"
                            v-bind:key="title.link"
                            class="viewCard"
                    >
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <router-link :to="title.link">
                                    <HoverCard :cardData="title"></HoverCard>
                                </router-link>
                            </template>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- <ProvinceList></ProvinceList>-->
        <!-- <DivisionList v-if="!provinceComponent"
         :provinceID="provinceID"></DivisionList>-->
    </v-content>
</template>

<script>
    import axios from "axios";
    import HoverCard from '@/components/Basic/HoverCard'
    /* import ProvinceList from "./ProvinceList";*/

    export default {
        name: "Dashboard",
        components: {
            HoverCard
        },
        computed: {
            titles() {
                return [
                    {
                        name: this.$t('questions_categories'),
                        link: { name: 'SurveyProvinceCategories' },
                        icon: "mdi-chart-areaspline"
                    }
                ]
            }
        },
        data() {
            return {
                items: [
                    {
                        text: "Dashboard",
                        disabled: true,
                        to: "/dashboard",
                        exact: true
                    }],
                provinces: [],
                provinceComponent: true,
                provinceID: null,
            }
        },
        mounted() {
            this.getProvince()
        },
        methods: {
            async getProvince() {
                try {
                    const response = await axios.get("Province/GetProvinceListByMinistry");
                    this.provinces = response.data;
                } catch (e) {
                    if (e.response.data.State == "AccessTokenExpired") {
                        this.apiResponse(e);
                        this.loadItems();
                    }
                }
            },
            /* loadDivision(provinceID) {
                 this.provinceComponent = false
                 this.provinceID = provinceID
             }*/
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>